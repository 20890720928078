import { connect } from "react-redux";
import { actions, selector } from "./redux";

export const mapDispatchToProps = {
  onSearch: (event) => actions.changeSearch(event.target.value),
  onClearSearch: actions.clearSearch,
  onColumnSort: actions.sortItems,
  onChangePageNumber: (e, { activePage }) =>
    actions.changePageNumber(activePage),
  getQuotes: actions.getQuotes,
  removeQuotes: actions.removeQuotes,
  loadData: actions.loadWithDefaultFilter,
  loadItem: actions.loadItemRequest,
  changeFilter: actions.changeFilter,
  removeFilter: actions.removeFilter,
  applyFilter: actions.applyFilter,
  clearFilter: actions.clearFilter,
  setActiveItemRow: actions.setActiveItemRow,
  setActiveQuoteRow: actions.setActiveQuoteRow,
  setQuoteItems: actions.setQuoteItems,
  setQuotesWithPrice: actions.setQuotesWithPrice,
  removeQuoteItems: actions.removeQuoteItems,
  clearActiveItemRow: actions.clearActiveItemRow,
  clearActiveQuoteRow: actions.clearActiveQuoteRow,
  setStatusFilter: actions.setStatusFilter,
  createRequest: actions.createRequest,
  cancelRequest: actions.cancelRequest,
};

export default connect(selector, mapDispatchToProps);
