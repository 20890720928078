import TagChipsComponent from "./TagChips";
import { connect } from 'react-redux';
import { selector, actions } from "./redux";

const mapDispatchToProps = {
  assignTag: actions.assignTagRequest,
  removeTag: actions.removeTagRequest
}

export const TagChips = connect(selector, mapDispatchToProps)(TagChipsComponent);

export default {
  TagChips
}
