import PropTypes from "prop-types";
import React, {useCallback, useEffect, useMemo} from "react";
import Layout from "components/pages/Layout";
import { Table } from "semantic-ui-react";

import TableHeader from "./TableHeader";
import isEmpty from "lodash/isEmpty";
import Paginator from "./Paginator";
import StatementReport from "../statement";
import TableRow from "./TableRow";

import "./styles/table.scss";
import {useLocalStorage} from "../../../components/App/LocalStorageContext";
import {REQUEST_CREATED_KEY, SUPPLIERS_SELECTED_KEY} from "../../requisition-lists/requests/utils";

export const CompaniesPage = ({
  data,
  sort,
  onColumnSort,
  isLoading,
  removeItem,
  loadTagList,
  loadData,
  showSelectRL = false,
}) => {
  const handleSort = useCallback(
    (column) => {
      onColumnSort(column);
    },
    [onColumnSort],
  );

  const isDataEmpty = useMemo(() => isEmpty(data), [data]);

  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);
  const [createdRequest, setCreatedRequest] = useLocalStorage(REQUEST_CREATED_KEY, null);

  useEffect(() => {
    loadData();
    loadTagList();
  }, [loadData, loadTagList]);

  return (
    <div className="table_content main-content">
      <StatementReport />
      <Table sortable size="small" compact="very">
        <TableHeader onColumnClick={handleSort} sort={sort} showSelectRL={showSelectRL}  />
        <Table.Body>
          {isDataEmpty && !isLoading && (
            <Table.Row>
              <Table.Cell>No data found</Table.Cell>
            </Table.Row>
          )}
          {!isDataEmpty &&
            data.map((item) => (
              <TableRow
                key={item.id}
                item={item}
                removeItem={removeItem}
                loadData={loadData}
                showSelectRL={showSelectRL}
                selectedSuppliers={selectedSuppliers}
                setSelectedSuppliers={setSelectedSuppliers}
                createdRequest={createdRequest}
              />
            ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row key="paginator-bottom">
            <Table.HeaderCell colSpan="15" textAlign="right">
              <Paginator />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

CompaniesPage.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired
  })).isRequired,
  isLoading: PropTypes.any.isRequired,
  loadData: PropTypes.func.isRequired,
  loadTagList: PropTypes.func.isRequired,
  onColumnSort: PropTypes.func.isRequired,
  removeItem: PropTypes.any.isRequired,
  sort: PropTypes.any.isRequired
}

export default Layout(CompaniesPage);
