import PropTypes from "prop-types";
import React from "react";
import { Step } from "semantic-ui-react";

const timelineStyle = {
  width: "100%",
};

const TimeLine = ({ children }) => (
  <Step.Group vertical size="mini" style={timelineStyle}>
    {children}
  </Step.Group>
);

TimeLine.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TimeLine;
