import { connect as reduxConnect } from "react-redux";
import { actions, selector } from "../redux";

/**
 * Dispatches actions to the Redux store.
 * @type {object}
 */
const mapDispatchToProps = {
  loadData: actions.loadListRequest, // Dispatches an action to initiate the loading of a list of items
  resetFilter: actions.resetFilter, // Dispatches an action to reset the filtering criteria
  resetPages: actions.resetPages, // Dispatches an action to reset the pagination
  setFilter: actions.setFilter, // Dispatches an action to set a filter
  setActivePage: actions.setActivePage, // Dispatches an action to set the currently active page
  createComment: actions.createItemRequest, // Dispatches an action to initiate the creation of a new item
};

/**
 * Connects a React component to the Redux store.
 * @param {function} Component – The component to connect to the Redux store.
 * @returns {function} The connected component.
 */
const connect = (Component) => {
  return reduxConnect(selector, mapDispatchToProps)(Component);
};

export default connect;
