import React, {useCallback, useState} from "react";
import {Button, Grid, Icon, Label} from "semantic-ui-react";


const colors = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
]

const CreateAndAssignTag = ({handleCreateAndAssignTag, search}) => {

  const [loading, setLoading] = useState(false);
  const [activeColor, setActiveColor] = useState(colors[0]);

  const handleChangeColor = useCallback((e, {color}) => {
    e.stopPropagation();
    setActiveColor(color);
  }, []);

  const handleButtonClick = useCallback((e, data) => {
    e.stopPropagation();

    if (loading) return;
    setLoading(true);
    handleCreateAndAssignTag(e, data);
    setTimeout(() => setLoading(false), 800);
  }, [loading, handleCreateAndAssignTag]);

  return (
    <>
      <Grid centered className="tag-list-chips-new-tag">
        <Grid.Row style={{alignItems: 'center'}}>
          {colors.map((tColor) => (
            <Label circular color={tColor} empty={activeColor !== tColor } key={tColor} onClick={handleChangeColor}/>
          ))}
        </Grid.Row>
      </Grid>
      <Button
        basic
        fluid
        size="mini"
        color={activeColor}
        loading={loading}
        onClick={handleButtonClick}
      >
        <Icon name="tag" />
        Set tag '{search}'
      </Button>
    </>
  )
}


export default CreateAndAssignTag;
