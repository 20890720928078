import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';
import connect from 'features/companies/statement/reduxConnect';

const getReports = ({customerId, totalStatementRequest}) => (
  [
    {
      name: 'Statement of Account',
      action: () => totalStatementRequest({customerId}),
      icon: 'table'
    }
  ]
);


const CompanyReports = (props) => {
  const reports = useMemo(() => getReports(props),
    [props]);

  return (
    <Dropdown trigger={ <span><Icon name="chart line" /> Reports </span> } >
      <Dropdown.Menu>
        {
          reports.map((report) => (
            <Dropdown.Item 
              as="a" 
              target="_blank" 
              key={ report.name } 
              text={ report.name }
              onClick={ report.action } 
              icon={ report.icon }
            />
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

CompanyReports.propTypes = {
  customerId: string,
  customerName: string
};

export default connect(CompanyReports);
