import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Label } from "semantic-ui-react";

const ActivityStatusCell = ({ children, value }) => {
  const color = useMemo(() => {
    return {
      neutral: "grey",
      disabled: "red",
      active: "green",
      regular: "teal",
    }[value];
  }, [value]);

  return (
    <Label style={{ width: "100%" }} color={color}>
      {children}
    </Label>
  );
};

ActivityStatusCell.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
};

export default ActivityStatusCell;
