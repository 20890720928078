import PropTypes from "prop-types"
import React from "react";
import { Popup, Card, Image, Rating } from "semantic-ui-react";
import CommentsFeed from "../CommentsFeed";
import connect from "./conncect";

const style = {
  width: "25vw",
  maxHeight: "30vh",
  overflowY: "auto",
};

const ConnectedComments = connect(CommentsFeed);
const PopupComments = ({ trigger, title, itemId, itemType, cb }) => (
  <Popup trigger={trigger} on="click" wide="very">
    <Popup.Header>Comments for [{title}]</Popup.Header>
    <Popup.Content style={style}>
      <ConnectedComments itemType={itemType} itemId={itemId} cb={cb} />
    </Popup.Content>
  </Popup>
);

PopupComments.propTypes = {
  cb: PropTypes.any,
  itemId: PropTypes.any,
  itemType: PropTypes.any,
  title: PropTypes.any,
  trigger: PropTypes.any
}

export default PopupComments;
