/**
 * Converts a snake case string to a normal case (also known as a title case).
 *
 * @param {string} str The string to be converted.
 * @returns {string} Returns the converted string.
 *
 * @example
 * // returns "Hello World"
 * snakeToNormalCase('hello_world')
 */
export function snakeToNormalCase(str) {
  // Replace underscores with spaces and convert to lowercase
  let words = str.replace(/_/g, ' ').toLowerCase();

  // Convert the first character of each word to uppercase
  return words.replace(/\b\w/g, function (letter) {
    return letter.toUpperCase();
  });
}
