import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {
  Feed,
  Grid,
  Icon,
  Pagination,
  Segment,
  Header,
} from "semantic-ui-react";

import "./styles/feed.scss";
import isEmpty from "lodash/isEmpty";
import NewCommentForm from "./NewCommentForm";

const CommentsFeed = (
  {
    data,
    loading,
    loadData,
    itemType,
    itemId,
    resetFilter,
    resetPages,
    setActivePage,
    setFilter,
    createComment,
    pagination: {activePage, totalPages},
    cb: afterCreateCb,
  }) => {
  const noData = isEmpty(data);

  const handlePageChange = (e, {activePage}) => {
    setActivePage(activePage);
    loadData();
  };

  useEffect(() => {
    resetFilter();
    resetPages();
    setFilter({commentable_type: itemType, commentable_id: itemId});
    loadData();
  }, []);

  return (
    <Segment className="feed-segment" textAlign="center" loading={loading}>
      {noData && (
        <Header icon>
          <Icon name="question circle outline"/>
          There are no comments for this company.
        </Header>
      )}
      <NewCommentForm
        createComment={createComment}
        loadData={loadData}
        itemType={itemType}
        itemId={itemId}
        afterCreateCb={afterCreateCb}
      />
      <Grid padded={false} className="comments-feed">
        <Grid.Row>
          <Grid.Column width="16">
            {totalPages > 1 && (
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                size="mini"
                onPageChange={handlePageChange}
              />
            )}
            <Feed>
              {data.map((item) => (
                <Feed.Event>
                  <Feed.Content>
                    <Feed.Summary>
                      <a>{item.attributes.author_name}</a> left comment
                      <Feed.Date>{item.attributes.created_at}</Feed.Date>
                    </Feed.Summary>
                    <Feed.Extra text>{item.attributes.body}</Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
              ))}
            </Feed>
            {totalPages > 1 && (
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                size="mini"
                onPageChange={handlePageChange}
              />
            )}
          </Grid.Column>
        </Grid.Row>

      </Grid>
    </Segment>
  );
};

CommentsFeed.propTypes = {
  cb: PropTypes.any,
  createComment: PropTypes.any,
  data: PropTypes.shape({
    map: PropTypes.func,
  }),
  itemId: PropTypes.any,
  itemType: PropTypes.any,
  loadData: PropTypes.func,
  loading: PropTypes.any,
  resetFilter: PropTypes.func,
  resetPages: PropTypes.func,
  setActivePage: PropTypes.func,
  setFilter: PropTypes.func,
};

export default CommentsFeed;
