import PropTypes from "prop-types"
import React from "react";
import { Button, Icon, Label, Menu, Popup } from "semantic-ui-react";
import PopupComments from "features/components/comments/CommentsPopup";
import MentionsPopup from "../../../components/mentions/MentionsPopup";
import PopupAdditionalInfo from "./PopupAdditionalInfo";

import "../styles/popups.scss";

const AdditionalInfo = ({
  companyId,
  loadData,
  attributes: { comments_count, mentions_count, ...attributes },
}) => {
  return (
    <>
      <MentionsPopup
        companyId={companyId}
        title={attributes.short_name}
        trigger={
          <Label size="tiny" basic className="popup-activator">
            <Icon name="at" />
            {mentions_count}
          </Label>
        }
      />

      <PopupComments
        itemType="Company"
        itemId={companyId}
        title={attributes.short_name}
        cb={loadData}
        trigger={
          <Label size="tiny" basic className="popup-activator">
            <Icon name="comment" />
            {comments_count}
          </Label>
        }
      />

      <PopupAdditionalInfo
        attributes={attributes}
        trigger={<Button icon="info" size="mini" basic />}
      />
    </>
  );
};

AdditionalInfo.propTypes = {
  companyId: PropTypes.any.isRequired,
  loadData: PropTypes.any.isRequired
}

export default AdditionalInfo;
