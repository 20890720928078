import get from "lodash/get";
import React, {useCallback, useEffect} from "react";
import {
  Form,
  Segment,
  TextArea,
  ModalHeader,
  ModalContent, SegmentGroup, Button
} from "semantic-ui-react";

import {SupplierSegment, RequisitionListSegment} from "./RequestSegments";

const styles = {
  segment: {
    width: '50%',
    maxHeight: 'calc(70vh)',
    overflowY: 'scroll'
  }
}

const CheckStep = ({message, setMessage, request}) => {

  const handleChangeMessage = useCallback((e, {value}) => {
    setMessage(value);
  }, [setMessage]);

  useEffect(() => {
    setMessage(get(request, 'attributes.message', ''));
  }, [request, setMessage])

  return (
    <>
      <ModalHeader>
        Check and send {get(request, 'attributes.title', '') || 'Request'}
      </ModalHeader>
      <ModalContent>
        <SegmentGroup horizontal>
          <Segment style={styles.segment}>
            <RequisitionListSegment/>
          </Segment>
          <Segment style={styles.segment}>
            <SupplierSegment/>
          </Segment>
        </SegmentGroup>
        <Segment>
          <Form>
            <TextArea
              placeholder='Message for suppliers'
              value={message}
              onChange={handleChangeMessage}
            />
          </Form>
        </Segment>
      </ModalContent>
    </>
  )
}

export default CheckStep;
