import React from 'react';
import connect from '../reduxConnect'

import { Paginator } from './TablePaginator';

import isEmpty from 'lodash/isEmpty';

const Pagination = ({ isLoading, pagination: { itemsPerPage, ...pagination }, onChangePageNumber, data }) => (
  <Paginator
    onPageChange={ onChangePageNumber }
    isLoading={ isLoading }
    isDataEmpty={ isEmpty(data) }
    { ...pagination }
  />
);

export default connect(Pagination);
