import PropTypes from "prop-types";
import React from "react";
import { Popup, Card, Image, Rating } from "semantic-ui-react";
import MentionsFeed from "../MentionsFeed";

import connect from "./conncect";

const style = {
  width: "25vw",
  maxHeight: "30vh",
  overflowY: "auto",
};

const ConnectedFeed = connect(MentionsFeed);

const PopupMentions = ({ trigger, title, companyId }) => (
  <Popup trigger={trigger} on="click" wide="very">
    <Popup.Header>Mentions for [{title}]</Popup.Header>
    <Popup.Content style={style}>
      <ConnectedFeed companyId={companyId} />
    </Popup.Content>
  </Popup>
);

PopupMentions.propTypes = {
  companyId: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  trigger: PropTypes.any.isRequired,
};

export default PopupMentions;
