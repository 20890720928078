import PropTypes from "prop-types"
import React, {Suspense, lazy} from 'react';
import {Loader} from 'semantic-ui-react';
import {SemanticToastContainer} from 'react-semantic-toasts';

import { Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../../store';

import actionCable from 'actioncable';

import {Context, store} from './Context';

import 'react-day-picker/lib/style.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-toasts/styles/react-semantic-alert.css';
import './app.scss';

import Modal from 'components/pages/Modal';
import RequireLogIn from 'components/pages/RequireLogIn';

import actionCableSubscribe from '../../utils/actioncable';
import {getWSBase} from '../../utils/api/api';

import {canAccessToPayroll} from 'utils/user';

import redux from "features/settings";

import get from 'lodash/get';

import {modalStylesTender, goBackActionTender} from './utils'

import './redesign.scss'
import CompaniesModal from "../../features/requisition-lists/requests/Companies";

const Login = lazy(() => import('components/pages/Login'));
const Profile = lazy(() => import('components/pages/Profile'));
const Dashboard = lazy(() => import('features/dashboard'));

const BankForm = lazy(() => import('components/forms/BankForm'));
const CompanyForm = lazy(() => import('features/companies/Form'));
const InvoiceForm = lazy(() => import('components/forms/InvoiceForm'));
const PaymentForm = lazy(() => import('components/forms/PaymentForm'));
const PurchaseOrderNewForm = lazy(() => import('features/purchase-orders/Form'));

const Payroll = lazy(() => import('features/payrolls'));
const PayrollForm = lazy(() => import('features/payrolls/component/Form'));

const InvoicesNew = lazy(() => import('features/invoices'));
const RequisitionLists = lazy(() => import('features/requisition-lists'));

const DeliveryNote = lazy(() => import('features/delivery-notes'));
const DeliveryNoteForm = lazy(() => import('features/delivery-notes/component/Form'));

const PaymentInstruction = lazy(() => import('features/payment-instructions'));
const PaymentInstructionForm = lazy(() => import('features/payment-instructions/component/Form'));
const PaymentInstructionPaymentForm = lazy(() => import('features/payment-instructions/component/PaymentForm'));

const ServiceFee = lazy(() => import('features/service-fees'));
const ServiceFeeForm = lazy(() => import('features/service-fees/form'));

const PartnerSchemes = lazy(() => import('features/partner-schemes/component/index'));

const Reports = lazy(() => import('components/Reports'));
const GeneralLedger = lazy(() => import('components/Reports/GeneralLedger'));
const BudgetPerformance = lazy(() => import('components/Reports/BudgetPerformance'));
const OALBudgetPerformance = lazy(() => import('components/Reports/OALBudgetPerformance'));
const PNLStatement = lazy(() => import('components/Reports/PNLStatement'));
const MonthlyBudgetPerformance = lazy(() => import('components/Reports/MonthlyBudgetPerformance'));
const ShipRepair = lazy(() => import('components/Reports/ShipRepair'));
const CashFlow = lazy(() => import('components/Reports/CashFlow'));
const CashFlowStatement = lazy(() => import('components/Reports/CashFlowStatement'));
const CashFlowNewStatement = lazy(() => import('components/Reports/CashFlowNewStatement'));
const InvoicesReport = lazy(() => import('components/Reports/Invoices'));
const InvoicesBudgetItemsReport = lazy(() => import('components/Reports/InvoicesBudgetItems'));
const PaymentInstructionsReport = lazy(() => import('components/Reports/PaymentInstructions'));
const Opex = lazy(() => import('components/Reports/OPEX'));
const NotInvoicedPO = lazy(() => import('components/Reports/NotInvoicedPO'));
const PayrollsReport = lazy(() => import('components/Reports/Payrolls'));

const Banks = lazy(() => import('components/pages/Banks'));
const Companies = lazy(() => import('features/companies'));
const Invoices = lazy(() => import('components/pages/Invoices'));
const PurchaseOrders = lazy(() => import('components/pages/PurchaseOrders'));

const CompanyBalance = lazy(() => import('features/companies/balance'));
const TenderTable = lazy(() => import('features/requisition-lists/tenders/TenderTable'));


const connect = redux('global');

class App extends React.Component {
  static propTypes = {
    setCurrentUser: PropTypes.func.isRequired
  }

  state = {
    company: store.company,
    user: store.user,
    invoicesDirection: store.invoicesDirection,
    updateCount: 0,
    cable: actionCable.createConsumer(getWSBase())
  };

  componentDidMount() {
    this.props.setCurrentUser(store.user);
  }

  update = () => {
    this.setState({...this.state, updateCount: this.state.updateCount + 1});
  }

  setItem = (key, value) => {
    store[key] = value;
    this.setState({[key]: value});
  };

  setCompany = (company) => {
    this.setItem('company', company);
  };

  setInvoicesDirection = (invoicesDirection) => {
    this.setItem('invoicesDirection', invoicesDirection);
  };

  setUser = (user) => {
    this.setItem('user', user);
  };

  setInvoicesPage = (invoicesPage) => {
    this.setItem('invoicesPage', invoicesPage);
  };

  setInvoicesYear = (invoicesYear) => {
    this.setItem('invoicesYear', invoicesYear);
  };

  subscribe = (payload, options) => actionCableSubscribe(this.state.cable, payload, options);

  render() {
    const contextValue = {
      ...this.state,
      update: this.update,
      setUser: this.setUser,
      setCompany: this.setCompany,
      setInvoicesDirection: this.setInvoicesDirection,
      setInvoicesPage: this.setInvoicesPage,
      setInvoicesYear: this.setInvoicesYear,
      subscribe: this.subscribe
    };

    return (
      <ConnectedRouter history={history}>
        <Context.Provider value={contextValue}>
          <Suspense fallback={<Loader active inline="centered"/>}>
            <SemanticToastContainer position="top-right"/>
            <div className="app">
              {/*COMMON ROUTES*/}
              <Route exact path="/">
                <Redirect to="/invoices"/>
              </Route>
              <Route component={RequireLogIn(Profile)} path="/profile"/>
              <Route component={RequireLogIn(InvoicesNew)} path="/invoices_new"/>

              <Route component={RequireLogIn(RequisitionLists)} path="/requisition_lists"/>
              <Route
                component={RequireLogIn(Modal(TenderTable, {size: 'fullscreen'}, modalStylesTender, goBackActionTender))}
                path="/requisition_lists/tenders/:id"/>
              <Route
                component={RequireLogIn(CompaniesModal)}
                path="/requisition_lists/requests/:id"/>


              <Route component={RequireLogIn(Invoices)} path="/invoices"/>
              <Route component={RequireLogIn(Modal(InvoiceForm))} path="/(invoices|invoices_new)/create"/>
              <Route component={RequireLogIn(Modal(InvoiceForm))} path="/(invoices|invoices_new)/copy/:fromId(\d+)"/>
              <Route component={RequireLogIn(Modal(PaymentForm))} path="/(invoices|invoices_new)/pay/:invoiceId(\d+)"/>
              <Route component={RequireLogIn(Modal(InvoiceForm))} path="/(invoices|invoices_new)/:id(\d+)"/>

              <Route component={RequireLogIn(Banks)} path="/banks"/>
              <Route component={RequireLogIn(Modal(BankForm, {size: 'small'}))} path="/banks/create"/>
              <Route component={RequireLogIn(Modal(BankForm, {size: 'small'}))} path="/banks/:id(\d+)"/>

              <Route component={RequireLogIn(Companies)} path="/companies"/>
              <Route component={RequireLogIn(Modal(CompanyForm))} path="/companies/create"/>
              <Route component={RequireLogIn(Modal(CompanyForm))} path="/companies/:id(\d+)"/>

              <Route component={RequireLogIn(PurchaseOrders)} path="/purchase_orders"/>
              <Route component={RequireLogIn(Modal(PurchaseOrderNewForm))} path="/purchase_orders/create"/>
              <Route component={RequireLogIn(Modal(PurchaseOrderNewForm))} path="/purchase_orders/copy/:fromId(\d+)"/>
              <Route component={RequireLogIn(Modal(PurchaseOrderNewForm))} path="/purchase_orders/:id(\d+)/edit"/>

              <Route component={RequireLogIn(PaymentInstruction)} path="/payment_instructions"/>
              <Route component={RequireLogIn(Modal(PaymentInstructionForm))} path="/payment_instructions/create"/>
              <Route component={RequireLogIn(Modal(PaymentInstructionForm))}
                     path="/payment_instructions/:id(\d+)/edit"/>
              <Route component={RequireLogIn(Modal(PaymentInstructionPaymentForm))}
                     path="/payment_instructions/:id(\d+)/pay"/>

              <Route component={RequireLogIn(DeliveryNote)} path="/delivery_notes"/>
              <Route component={RequireLogIn(Modal(DeliveryNoteForm))} path="/delivery_notes/create"/>
              <Route component={RequireLogIn(Modal(DeliveryNoteForm))} path="/delivery_notes/:id(\d+)/edit"/>

              <Route component={RequireLogIn(ServiceFee)} path="/service_fees"/>
              <Route component={RequireLogIn(Modal(ServiceFeeForm))} path="/service_fees/create"/>
              <Route component={RequireLogIn(Modal(ServiceFeeForm))} path="/service_fees/:id(\d+)/edit"/>

              {/*ADMIN ONLY ROUTES*/}
              {get(this.state.user, 'role') === 'admin' &&
                <>
                  <Route component={RequireLogIn(CompanyBalance)} path="/companies_balance"/>
                  <Route component={RequireLogIn(PartnerSchemes)} path="/partner_schemes"/>
                </>
              }

              {/*PAYROLL ACCESS ONLY ROUTES*/}
              {canAccessToPayroll(this.state.user) &&
                <>
                  <Route component={RequireLogIn(Payroll)} path="/payrolls"/>
                  <Route component={RequireLogIn(Modal(PayrollForm, {size: 'fullscreen'}))} path="/payrolls/create"/>
                  <Route component={RequireLogIn(Modal(PayrollForm, {size: 'fullscreen', cancelName: 'Exit'}))}
                         path="/payrolls/:id(\d+)/edit"/>
                  <Route component={RequireLogIn(Modal(PayrollsReport, {size: 'fullscreen'}))}
                         path="/reports/payrolls"/>
                </>
              }
              {/*REPORTS*/}
              <Route component={RequireLogIn(Reports)} path="/reports"/>
              <Route component={RequireLogIn(Modal(GeneralLedger, {size: 'fullscreen'}))}
                     path="/reports/general_ledger"/>
              <Route component={RequireLogIn(Modal(BudgetPerformance, {size: 'fullscreen'}))}
                     path="/reports/budget_performance"/>
              <Route component={RequireLogIn(Modal(OALBudgetPerformance, {size: 'fullscreen'}))}
                     path="/reports/oal_budget_performance"/>
              <Route component={RequireLogIn(Modal(PNLStatement, {size: 'fullscreen'}))} path="/reports/pnl_statement"/>
              <Route component={RequireLogIn(Modal(CashFlowNewStatement, {size: 'fullscreen'}))}
                     path="/reports/cash_flow_new_statement"/>
              <Route component={RequireLogIn(Modal(MonthlyBudgetPerformance, {size: 'fullscreen'}))}
                     path="/reports/monthly_budget_performance"/>
              <Route component={RequireLogIn(Modal(ShipRepair, {size: 'fullscreen'}))} path="/reports/ship_repair"/>
              <Route component={RequireLogIn(Modal(CashFlow, {size: 'fullscreen'}))} path="/reports/cash_flow"/>
              <Route component={RequireLogIn(Modal(CashFlowStatement, {size: 'fullscreen'}))}
                     path="/reports/cash_flow_statement"/>
              <Route component={RequireLogIn(Modal(InvoicesReport, {size: 'fullscreen'}))} path="/reports/invoices"/>
              <Route component={RequireLogIn(Modal(InvoicesBudgetItemsReport, {size: 'fullscreen'}))}
                     path="/reports/invoices_budget_items"/>
              <Route component={RequireLogIn(Modal(PaymentInstructionsReport, {size: 'fullscreen'}))}
                     path="/reports/payment_instructions"/>
              <Route component={RequireLogIn(Modal(Opex, {size: 'fullscreen'}))} path="/reports/opex"/>
              <Route component={RequireLogIn(Modal(NotInvoicedPO, {size: 'fullscreen'}))}
                     path="/reports/not_invoiced_po"/>
              <Route component={Login} path="/login"/>
            </div>
          </Suspense>
        </Context.Provider>
      </ConnectedRouter>
    );
  }
}


export default connect(App);
