import React, { useCallback, useMemo, useState } from 'react';
import {Button, Dropdown, Icon, Input, Label} from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import './styles.scss'
import CreateAndAssignTag from "./CreateAndAssignTag";

const buildTagOption =({id, attributes: {name, color}}, tags, loading) => ({
  key: id,
  text: name,
  value: name,
  disabled: loading || tags.map(({name: n}) => n).includes(name),
  label: { color, empty: true, circular: true },
});


const TagChips = (
  {
    tags,
    itemType, itemId,
    data: tagList,
    canAdd, canRemove,
    assignTag, removeTag,
    cb
  }) => {

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const filteredList = useMemo(() => {
    if (isEmpty(search)) return tagList;

    return tagList.filter(({attributes}) => attributes.name.toLowerCase().includes(search.toLowerCase()))
  }, [search, tagList]);

  const handleSearchTag = useCallback((e, { value }) => {
    e.stopPropagation();
    setSearch(value);
  }, []);

  const clearSearch = useCallback((e) => {
    e.stopPropagation();
    setSearch('');
  }, []);

  const handleAssignTag = useCallback((e, { value, color }) => {
    e.stopPropagation();

    setLoading(true);

    const fullCb = () => {
      setLoading(false);
      cb();
    }
    assignTag({itemType, itemId, tag: value, cb: fullCb})
  }, [itemType, itemId, cb]);

  const handleCreateAndAssignTag = useCallback((e, { color }) => {
    e.stopPropagation();

    const fullCb = () => {
      setSearch('');
      cb();
    }
    assignTag({itemType, itemId, tag: search, color, cb: fullCb})
  }, [cb, itemType, itemId, search]);

  const handleRemoveTag = useCallback((e, { value }) => {
    e.stopPropagation();
    removeTag({itemType, itemId, tag: value, cb})
  }, [itemType, itemId, cb]);

  const handleInputKeyDown =  useCallback((e) => {
    if (e.keyCode === 32) { // 32 is the key code for space
      e.stopPropagation();
    }
  }, []);

  const inputAction = useMemo(
    () => ({icon: 'close', onClick: clearSearch, size: 'mini'}),
    [clearSearch]
  );

  const handleInputClick =  useCallback((e) => e.stopPropagation(), []);

  return (
    <div className="tag-list-chips">
      <Dropdown
        icon={null}
        closeOnEscape={false}
        forceSelection={false}
        trigger={
          canAdd && (
            <Button icon basic size="mini" color="teal">
              <Icon name="add" />
            </Button>
          )
        }
      >
        <Dropdown.Menu style={{minWidth: '250px'}}>
          <Input
            icon="search"
            iconPosition="left"
            className="search"
            value={search}
            onKeyDown={handleInputKeyDown}
            onClick={handleInputClick}
            onChange={handleSearchTag}
            action={inputAction}
          />
          {!isEmpty(search) && <CreateAndAssignTag handleCreateAndAssignTag={handleCreateAndAssignTag} search={search}/>}
          <Dropdown.Divider />
          {!isEmpty(filteredList) && (
            <>
              <Dropdown.Header icon={loading ? "spinner" : "tags"} content={loading ? "Loading..." : "Select tag"} />
              <Dropdown.Menu scrolling>
                {filteredList.map((option) => (
                  <Dropdown.Item
                    key={option.id}
                    {...buildTagOption(option, tags, loading)}
                    onClick={handleAssignTag}
                  />
                ))}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      {tags.map((tag) => (
        <Label circular key={tag} basic size="mini" color={tag.color}>
          {tag.name}
          {canRemove && (
            <Icon name="delete" value={tag.name} onClick={handleRemoveTag} />
          )}
        </Label>
      ))}
    </div>
  );

}

TagChips.propTypes = {
  tags: PropTypes.array,
  itemType: PropTypes.string.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.array.isRequired,
  canAdd: PropTypes.bool,
  canRemove: PropTypes.bool,
  assignTag: PropTypes.func.isRequired,
  removeTag: PropTypes.func.isRequired,
  cb: PropTypes.func
};

TagChips.defaultProps = {
  canAdd: true,
  canRemove: true,
  tags: [],
}


export default TagChips;
