import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo } from "react";
import {
  Feed,
  Grid,
  Header,
  Icon,
  Pagination,
  Segment,
} from "semantic-ui-react";

import "./styles/feed.scss";
import isEmpty from "lodash/isEmpty";
import { getIcon } from "./utils";

const MentionsFeed = ({
  data,
  loading,
  loadData,
  companyId,
  resetFilter,
  resetPages,
  setActivePage,
  setFilter,
  pagination: { activePage, totalPages },
}) => {
  const noData = useMemo(() => isEmpty(data), [data]);

  const handlePageChange = useCallback(
    (e, { activePage }) => {
      setActivePage(activePage);
      loadData();
    },
    [activePage, loadData],
  );

  useEffect(() => {
    resetFilter();
    resetPages();
    setFilter({ company_id: companyId });
    loadData();
  }, []);

  return (
    <Segment className="feed-segment" placeholder={noData} loading={loading}>
      {noData && (
        <Header icon>
          <Icon name="question circle outline" />
          There are no mentions for this company.
        </Header>
      )}
      <Grid className="comments-feed">
        <Grid.Row>
          <Grid.Column width="16">
            <Feed>
              {data.map((item) => (
                <Feed.Event>
                  <Feed.Label>
                    <Icon name={getIcon(item.attributes.mention_as)} />
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Date>{item.attributes.mention_at}</Feed.Date>
                    <Feed.Extra text>
                      Was mentioned in document{" "}
                      <a href={item.attributes.doc_present.url} target="_blank">
                        {item.attributes.doc_present.title}
                      </a>{" "}
                      as <b>{item.attributes.mention_as}</b>.
                    </Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
              ))}
            </Feed>
          </Grid.Column>
        </Grid.Row>
        {totalPages > 1 && (
          <Grid.Row textAlign="center">
            <Grid.Column width="16">
              <Pagination
                activePage={activePage}
                totalPages={totalPages}
                size="mini"
                onPageChange={handlePageChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Segment>
  );
};

MentionsFeed.propTypes = {
  companyId: PropTypes.any,
  data: PropTypes.shape({
    map: PropTypes.func,
  }),
  loadData: PropTypes.func,
  loading: PropTypes.any,
  resetFilter: PropTypes.func,
  resetPages: PropTypes.func,
  setActivePage: PropTypes.func,
  setFilter: PropTypes.func,
};

export default MentionsFeed;
