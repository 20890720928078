import { connect } from 'react-redux';
import { actions, selector } from './redux';
import { actions as tagActions } from '../components/tags/redux';
import {createAction} from "redux-actions";

export const mapDispatchToProps = {
  onSearch: (event) => actions.changeSearch(event.target.value),
  onClearSearch: actions.clearSearch,
  onColumnSort: actions.sortItems,
  onChangePageNumber: (e, { activePage }) => actions.changePageNumber(activePage),
  loadData: actions.loadItemsRequest,
  loadItem: actions.loadItemRequest,
  removeItem: actions.deleteItemRequest,
  changeFilter: actions.changeFilter,
  removeFilter: actions.removeFilter,
  applyFilter: actions.applyFilter,
  clearFilter: actions.clearFilter,
  loadTagList: tagActions.loadTagListRequest,
  createItem: actions.createItemRequest,
  updateItem: actions.updateItemRequest,
};

export default connect(selector, mapDispatchToProps);

