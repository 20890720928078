/**
 * Module dependencies.
 * @module myModule
 */

import React from "react";
import { connect } from "react-redux";
import TimelineStatusHistory from "./component";
import { actions, selector } from "./redux";

/**
 * Map redux dispatch to component props
 * @type {Object} mapDispatchToProps
 * @property {Function} loadHistory - Action to fetch items
 */
const mapDispatchToProps = {
  loadHistory: actions.loadItemsRequest,
};

/**
 * Connects redux state and dispatch to React props
 * @type {Function} connect
 * @param {Function} selector - Function to select part of redux state
 * @param {Object} mapDispatchToProps - An object containing redux actions
 * @returns {Function} - A Redux connected React component
 */
const ConnectedTimelineStatusHistory = connect(
  selector,
  mapDispatchToProps,
)(TimelineStatusHistory);

/**
 * Default export to create an element of ConnectedTimelineStatusHistory
 * @function
 * @param {React.JSX.Element} element - The DOM element to attach component
 * @param {string} statusName - The name of the status
 * @param {string} docType - The document type
 * @param {string} docId - The document ID
 * @returns {React.Element} - React Element (JSX)
 */
export default (element, statusName, docType, docId) =>
  React.createElement(ConnectedTimelineStatusHistory, {
    trigger: element,
    statusName,
    docType,
    docId,
  });
