import PropTypes from "prop-types";
import React from "react";
import { Feed } from "semantic-ui-react";
import get from "lodash/get";

const Updated = ({ lastUpdated }) => {
  return (
    <Feed>
      <Feed.Event>
        <Feed.Content>
          <Feed.Summary>
            <Feed.User>{get(lastUpdated, "updated_by", "")}</Feed.User>
            <Feed.Date>{get(lastUpdated, "updated_at", "")}</Feed.Date>
          </Feed.Summary>
        </Feed.Content>
      </Feed.Event>
    </Feed>
  );
};

Updated.propTypes = {
  lastUpdated: PropTypes.shape({
    updated_by: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
};

export default Updated;
