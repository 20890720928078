import { connect } from 'react-redux';
import { actions, selector } from './redux';

const mapDispatchToProps = {
  showStatementModal: () => actions.showStatementModal(),
  hideStatementModal: () => actions.hideStatementModal(),
  totalStatementRequest: actions.totalStatementRequest,
  companyStatementDownload: actions.companyStatementDownload,
};


export default connect(selector, mapDispatchToProps);
