import PropTypes from "prop-types"
import React from "react";
import {Button, Icon} from "semantic-ui-react";
import get from "lodash/get";

import {Invoices, PurchaseOrders, PaymentInstructions} from 'features/components/documents-popup';

const scaledStyle = {
  transform: "scale(0.85)",
};

const style = {
  cursor: "initial",
};

const DocsCounter = ({companyId, docsCount, scaled}) => (
  <div style={scaled ? scaledStyle : null}>
    <Invoices companyId={companyId} trigger={
      <Button circular size="mini" color="blue" style={style}>
        {get(docsCount, "invoices", 0)}
      </Button>
    }/>
    <PurchaseOrders companyId={companyId} trigger={
      <Button circular size="mini" color="green" style={style}>
      {get(docsCount, "purchase_orders", 0)}
    </Button>
    }/>
    <PaymentInstructions companyId={companyId} trigger={
      <Button circular size="mini" color="orange" style={style}>
      {get(docsCount, "payment_instructions", 0)}
    </Button>
    }/>
  </div>
);

DocsCounter.propTypes = {
  docsCount: PropTypes.any.isRequired,
  scaled: PropTypes.any.isRequired
}

export default DocsCounter;
