import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { selectOptions as selectOptionsApi } from 'utils/resources';
import {tryParseDate} from "./date";
import {CurrentUser} from "./user";

export const useCurrencyOptions = (date = null) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  const formattedDate = useMemo(() => {
    let _date = date || new Date();
    try {
      return tryParseDate(_date).toJSON().split('T')[0];
    } catch (e) {
      return null;
    }
  }, [date]);

  const params = useMemo(() => ({
    date: formattedDate,
    type: 'currencies',
    f_label: 'code',
    f_value: 'code',
    count: 'all',
  }), [date]);

  useEffect(() => {
    selectOptionsApi.index(params)
      .then((response) => {
        const data = response.map(({value, label, rate}) => ({key: value, value, text: `${label} ${rate ? `[${rate}]` : ''}`, rate}))
        setOptions(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [date]);

  return options;
}


export const useSelectOptions = (selectOptions) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const isUseSelectOptions = selectOptions && Object.keys(selectOptions).length > 0;

  const params = useMemo(() => ({
    count: 'all',
    f_label: 'title',
    f_value: 'id',
    ...selectOptions
  }), [selectOptions]);

  const mapDataToOptions = useCallback((row) => {
    const {value, label, ...other} = row;
    return {
      key: value,
      value,
      text: label,
      ...other
    }
  }, [])

  useEffect(() => {
    if (!isUseSelectOptions) return;

    setLoading(true);
    selectOptionsApi.index(params)
    .then((response) => {
      const data = response.map(mapDataToOptions)
      setOptions(data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }, [params]);

  return [loading, options];
}

export const useCurrentUser  = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = new CurrentUser();
    setCurrentUser(user);
  }, []);

  return currentUser;
}
