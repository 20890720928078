import React, {useCallback, useMemo} from "react";
import {Button, ButtonGroup, Icon, Label} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {useLocalStorage} from "../../../../components/App/LocalStorageContext";
import {SUPPLIERS_SELECTED_KEY} from "../utils";

const SelectCompanies = () => {

  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);

  const data = useSelector(s => s.companiesPage.data);

  const showCount = useMemo(() => data.length, [data]);
  const clearCount = useMemo(() => selectedSuppliers.length, [selectedSuppliers]);


  const handleSelectAll = useCallback(() => {
    const newSelected = [...new Set([...selectedSuppliers, ...data])];
    setSelectedSuppliers(newSelected);
  }, [data, selectedSuppliers, setSelectedSuppliers]);

  const handleUnselectAll = useCallback(() => {
    setSelectedSuppliers([]);
  }, [setSelectedSuppliers]);

  return (
    <ButtonGroup basic size="tiny" className="SelectCompanies">
      <Button onClick={handleSelectAll}>
        <Icon name="check square outline"/>
        <span>Select all</span>
        {!!showCount && <Label size="mini" color="green" circular>{showCount}</Label>}
      </Button>
      <Button onClick={handleUnselectAll}>
        <Icon name="square outline"/>
        <span>Clear all</span>
        { !!clearCount && <Label size="mini" color="red" circular>{clearCount}</Label> }
      </Button>
    </ButtonGroup>
  )
}

export default SelectCompanies;
