import get from "lodash/get";
import rlApi from 'utils/resources/requisitionLists'
import suppApi from 'utils/resources/companies'
import api from 'utils/resources/requestForQuotations'

export const requestManager = () => {

  const loadRequests = (id, onSuccess) => {
    api.show(id)
     .then(({data: {data}}) => onSuccess(data))
  }

  const loadRequisitionLists = (ids = [], onSuccess) => {
    if (!ids.length) return;
    rlApi.api
      .read({type: "api_resources/requisition_lists"}, {query: { filter: { id: ids }}})
      .then(({data: {data}}) => onSuccess(data));
  }

  const loadSuppliers = (ids, onSuccess) => {
    if (!ids.length) return;
    suppApi.api
      .read({type: "api_resources/companies"}, {query: { filter: { id: ids }}})
      .then(({data: {data}}) => onSuccess(data));
  }

  const createRequest = (payload, onSuccess) => {
    api.create(payload)
       .then(({data: {data}}) => onSuccess(data))
  }

  const updateRequest = (id, payload, onSuccess) => {
    api.update(id, payload)
      .then(({data: {data}}) => onSuccess(data))
  }

  const addRLToRequest = (request, rlId, onSuccess) => {
    const requestId = get(request, 'id');

    if (requestId) {
      api.addRequisitionList(requestId, rlId)
      .then(onSuccess)
    } else {
      onSuccess()
    }
  }

  const removeRLFromRequest = (request, rlId, onSuccess) => {
    const requestId = get(request, 'id');

    if (requestId) {
      api.removeRequisitionList(requestId, rlId)
        .then(onSuccess)
    } else {
      onSuccess()
    }
  }

  const addSupplierToRequest = (request, supplierId, onSuccess) => {
    const requestId = get(request, 'id');

    if (requestId) {
      api.addSupplier(requestId, supplierId)
       .then(onSuccess)
    } else {
      onSuccess()
    }
  }

  const removeSupplierFromRequest = (request, supplierId, onSuccess) => {
    const requestId = get(request, 'id');

    if (requestId) {
      api.removeSupplier(requestId, supplierId)
       .then(onSuccess)
    } else {
      onSuccess()
    }
  }


  return {
    loadRequests,
    loadRequisitionLists,
    loadSuppliers,
    createRequest,
    updateRequest,
    addRLToRequest,
    removeRLFromRequest,
    addSupplierToRequest,
    removeSupplierFromRequest,
  }
}
