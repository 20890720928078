import React, {useCallback, useEffect, useState} from "react";
import {
  Button,
  Pagination,
  Popup,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from "semantic-ui-react";

function formatLongText(text) {
  const maxCharsCount = 70
  if (text.length <= maxCharsCount) {
    return text;
  } else {
    return `${text.slice(0, maxCharsCount/2)}...${text.slice(-maxCharsCount/2)}`;
  }
}

const PopupBaseComponent = ({api, filter, fields, trigger}) => {

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({activePage: 1, itemsPerPage: 10, totalPages: 1});

  const handlePageChange = useCallback((e, {activePage}) => {
    setPagination(s => ({...s, activePage}));
  }, [setPagination]);

  useEffect(() => {
    if (!open) return;

    api.index(pagination, undefined, undefined, filter, undefined, {fields}).then(({data: {data, meta}}) => {
      setData(data);
      setPagination(s => ({...s, itemsPerPage: meta.per_page, totalPages: meta.page_count}));
    });
  }, [open, pagination.activePage]);

  return (
    <Popup open={open} trigger={trigger} position="left center" on='click' onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
      <Popup.Header>Documents List</Popup.Header>
      <Popup.Content style={{width: '550px'}}>
        <Table stackable size="small" compact="very">
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Document</TableHeaderCell>
              <TableHeaderCell>Action</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            {data.map(item => (
              <TableRow key={item.id}>
                <TableCell>{formatLongText(item.attributes.presentation)}</TableCell>
                <TableCell textAlign='right'>
                  <Button
                    circular
                    basic
                    size="mini"
                    as='a'
                    href={item.attributes.item_url}
                    target="_blank"
                    icon='external alternate'
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='3' textAlign="center">
                <Pagination
                  defaultActivePage={1}
                  firstItem={null}
                  lastItem={null}
                  pointing
                  secondary
                  onPageChange={handlePageChange}
                  {...pagination}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Popup.Content>
    </Popup>

  )

}


export default PopupBaseComponent;
