import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Icon, Step } from "semantic-ui-react";
import statusIcons from "./icons";
import { snakeToNormalCase } from "./utils";

const itemStyle = {
  padding: "5px",
};

const StatusItem = ({ status, date, author, prevStatus }) => {
  const prevStatusTitle = useMemo(
    () => (prevStatus ? `${snakeToNormalCase(prevStatus)} -> ` : ""),
    [prevStatus],
  );
  const statusTitle = useMemo(
    () => `${prevStatusTitle}${snakeToNormalCase(status)}`,
    [prevStatusTitle, status],
  );

  return (
    <Step style={itemStyle}>
      <Icon name={statusIcons(status)} />
      <Step.Content>
        <Step.Title>{statusTitle}</Step.Title>
        <Step.Description>{date}</Step.Description>
        <Step.Content>
          <strong>{author}</strong>
        </Step.Content>
      </Step.Content>
    </Step>
  );
};

StatusItem.propTypes = {
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  prevStatus: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusItem;
