import PropTypes from "prop-types";
import { Button, Form, TextArea } from "semantic-ui-react";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const NewCommentForm = ({
  createComment,
  loadData,
  itemType,
  itemId,
  afterCreateCb,
}) => {
  const currentUser = useSelector((state) => state.settings.global.currentUser);

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    body: "",
  });

  const handeFormChange = useCallback(
    (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    },
    [form],
  );

  const handeCreateComment = useCallback(() => {
    if (!form.body) return;

    setLoading(true);
    const payload = {
      ...form,
      commentable_type: itemType,
      commentable_id: itemId,
      user_id: currentUser.id,
      cb: () => {
        setLoading(false);
        loadData();
        setForm({ body: "" });
        if (afterCreateCb) afterCreateCb();
      },
    };

    createComment(payload);
  }, [currentUser, createComment, loadData, itemType, itemId, form]);

  return (
    <Form>
      <Form.Group>
        <TextArea
          fluid
          name="body"
          placeholder="Add new comment..."
          value={form.body}
          onChange={handeFormChange}
        />
        <Button
          basic
          primary
          loading={loading}
          onClick={handeCreateComment}
          icon="comment alternate outline"
        />
      </Form.Group>
    </Form>
  );
};

NewCommentForm.propTypes = {
  afterCreateCb: PropTypes.func,
  createComment: PropTypes.func,
  itemId: PropTypes.any,
  itemType: PropTypes.any,
  loadData: PropTypes.func,
};

export default NewCommentForm;
