import reduxConnect from "../../../companies/reduxConnect";
import {CompaniesPage} from "../../../companies/component";
import {ModalContent, ModalHeader} from "semantic-ui-react";
import {FilterHeader} from "./FilterHeader";
import React from "react";
import get from "lodash/get";

const ConnectedCompaniesPage = reduxConnect(CompaniesPage);
const SuppliersStep = ({request}) => {

  return (
    <>
      <ModalHeader>Select Suppliers for {get(request, 'attributes.title') || 'New Request'  }</ModalHeader>
      <ModalContent>
        <FilterHeader/>
        <ConnectedCompaniesPage showSelectRL/>
      </ModalContent>
    </>
  )
}

export default SuppliersStep;
