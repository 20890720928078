import React from 'react';
import { Loader, Pagination } from 'semantic-ui-react';

export const Paginator = ({ isLoading, isDataEmpty, ...props }) => (
  <span>
    {isLoading && <Loader active inline />}
    {!isLoading && !isDataEmpty &&
    <Pagination
      pointing
      secondary
      size="mini"
      firstItem={ null }
      lastItem={ null }
      { ...props }
    />
    }
  </span>
);
