/**
 * Map of icons and their associated strings.
 * @type {object} iconList - The map of icons and their associated strings.
 */
const iconList = {
  bank: "university",
  intermediary_bank: "random",
  agent: "user",
};

/**
 * Function to return the appropriate icon type.
 * If there is no icon prescribed for a specific type, it defaults to 'question circle'.
 *
 * @export
 * @param {string} type - The entity type for which the icon should be fetched.
 * @return {string} The icon associated with the given type or 'question circle' if not found.
 */
export const getIcon = (type) => {
  return iconList[type] || "question circle";
};
