/**
 * Object that maps various statuses to their corresponding icon names.
 * @type {{[key]: string}}
 */
const icons = {
  // status PO
  draft:'file outline',
  ordered:'arrow down cart',
  completed:'check circle outline',
  checked:'list',
  unpaid:'wait',
  partly_paid:'chart pie',
  cancelled:'ban',
  paid: 'dollar',
  // logistic status PO
  acknowledged: 'check circle outline',
  ready: 'check square outline',
  in_transit: 'shipping fast',
  consolidated: 'boxes',
  on_schedule: 'calendar check outline',
  delivered: 'ship',
}

/**
 * Function that accepts a status and returns the corresponding icon.
 * If the status is not found in the icon object, it defaults to 'list'.
 *
 * @param {string} status - The status to get the icon of.
 * @returns {string} The name of the icon corresponding to the status.
 */
export default (status) => icons[status] || 'list'
