/**
 * Creates a data payload object for API requests.
 *
 * @param {string} dataType - The type of data being sent.
 * @param {Object} dataAttributes - The attributes of the data.
 * @param {string|number} [id] - The optional ID of the data.
 * @returns {Object} The formatted data payload object.
 */
export const dataPayload = (dataType, dataAttributes, id) => ({
  data: {
    ...(id && { id }),
    type: dataType,
    attributes: dataAttributes
  }
});
