import { connect as reduxConnect } from "react-redux";
import { actions, selector } from "../redux";

/**
 * Function used to connect the redux state with the Component
 * @param {Object} Component - A Component to be connected with the redux state
 * @returns {Object} Returns a redux connected Component
 */
const connect = (Component) => {
  return reduxConnect(selector, mapDispatchToProps)(Component);
};

/**
 * Object containing the functions to be used as dispatch properties on connected Component
 * @namespace
 * @property {function} loadData - Dispatch function that triggers the request for data loading
 * @property {function} resetFilter - Dispatch function that resets the filter in redux state
 * @property {function} resetPages - Dispatch function that resets the pages in redux state
 * @property {function} setFilter - Dispatch function that sets a new filter in redux state
 * @property {function} setActivePage - Dispatch function that sets a new active page in pagination in redux state
 */
const mapDispatchToProps = {
  loadData: actions.loadListRequest,
  resetFilter: actions.resetFilter,
  resetPages: actions.resetPages,
  setFilter: actions.setFilter,
  setActivePage: actions.setActivePage,
};

export default connect;
