import React, {useCallback, useEffect, useState} from "react";
import {Button, Modal, ModalActions} from "semantic-ui-react";
import '../styles/companies.scss'
import SuppliersStep from "./SuppliersStep";
import CheckStep from "./CheckStep";
import reduxConnect from "../../reduxConnect";
import {useLocalStorage} from "../../../../components/App/LocalStorageContext";
import {REQUEST_CREATED_KEY, RL_SELECTED_KEY, SUPPLIERS_SELECTED_KEY} from "../utils";
import {history} from "../../../../store";
import {requestManager} from "../../requestManager";

const CompaniesModal = (
  {
    createRequest,
    match: { params: {id} },
  }) => {

  const [request, setRequest] = useState(null);

  const open = true
  const setShowModal = console.log;

  const [selectedItems, setSelectedItems] = useLocalStorage(RL_SELECTED_KEY, []);
  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);
  const [createdRequest, setCreatedRequest] = useLocalStorage(REQUEST_CREATED_KEY, null);

  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState(false);

  // wizard steps:
  // 0 - select suppliers
  // 1 - check and send request
  const [currentStep, setCurrentStep] = useState(0);

  const handleClose = useCallback((e) => {
    e.preventDefault();
    history.push('/requisition_lists');
  }, []);

  const handlePrevStep = useCallback(() => {
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
  }, [currentStep, setCurrentStep]);

  const handleNextStep = useCallback(() => {
    const newStep = currentStep + 1;
    if (newStep === 2) {
      setDisabled(true);
      const onSuccess = () => {
        setCreatedRequest(null)
        setSelectedSuppliers([]);
        setSelectedItems([]);
        history.push('/requisition_lists');
      }
      const payload = {
        message,
        status: 'active'
      }
      requestManager().updateRequest(id, payload, onSuccess);
      setTimeout(() => setDisabled(false), 2000);
    } else {
      setCurrentStep(newStep);
    }
  }, [currentStep, message, id]);

  useEffect(() => {
    if (!id) return;
    const onSuccess = (data) => {
      setRequest(data);
      setCreatedRequest(data);
      requestManager().loadRequisitionLists(data.attributes.requisition_list_ids, setSelectedItems);
      const suppOnSuccess = (data) => {
        setSelectedSuppliers(data);
        if(data.length) {
          setCurrentStep(1);
        }
      }
      requestManager().loadSuppliers(data.attributes.supplier_ids, suppOnSuccess);
    }
    requestManager().loadRequests(id, onSuccess)

    return () => {
      setCurrentStep(0);
    }
  }, [id, open]);

  return (
    <Modal
      size='fullscreen'
      open={open}
      className="CompaniesModal"
    >
      {currentStep === 0 && <SuppliersStep request={request}/>}
      {currentStep === 1 && <CheckStep request={request} message={message} setMessage={setMessage}/>}
      <ModalActions>
        <Button onClick={handleClose}>
          Close
        </Button>
        {currentStep > 0 &&
          <Button color="olive" onClick={handlePrevStep}>
            Previous
          </Button>
        }
        <Button positive onClick={handleNextStep} disabled={disabled} loading={disabled}>
          {currentStep === 1 ? 'Send' : 'Next'}
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default reduxConnect(CompaniesModal);
