import {useSelectOptions} from "../../../../utils/hooks";
import React from "react";
import FilterMultiSelect from "./FilterMultiSelect";

const typeSelectOptionParams = {type: 'company_types', f_label: 'name'};
const FilterTypes = () => {
  const [typeLoading, typeOptions] = useSelectOptions(typeSelectOptionParams);

  return (
    <FilterMultiSelect
      loading={typeLoading}
      options={typeOptions}
      label="Types"
      filterKey="types"
    />
  );
}

export default FilterTypes;
