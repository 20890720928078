import Search from "../../../companies/component/Search";
import React from "react";
import FilterTags from "./FilterTags";
import FilterTypes from "./FilterTypes";
import SelectCompanies from "./SelectCompanies";

export const FilterHeader = () => {

  return (
    <div className="CompaniesModal-FilterHeader">
      <Search/>
      <FilterTags/>
      <FilterTypes />
      <div className="CompaniesModal-FilterHeader__divider"/>
      <SelectCompanies />
    </div>
  )
}
