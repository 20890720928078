import {
  Dropdown,
  DropdownDivider,
  DropdownHeader,
  DropdownItem,
  DropdownMenu,
  Icon,
  Input
} from "semantic-ui-react";
import React, {useCallback, useEffect, useState} from "react";

import reduxConnect from "features/companies/reduxConnect";

const styles = {
  icons: {
    float: 'right'
  },
  icon: {
    cursor: 'pointer'
  }
}

const FilterMultiSelect = ({loading, options, label, filterKey, changeFilter, applyFilter}) => {
  const [visibleOptions, setVisibleOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState('');

  const handleSelect = useCallback((e, {value}) => {
    e.stopPropagation();
    if (selected.includes(value)) {
      setSelected(selected.filter(tag => tag !== value));
    } else {
      setSelected([...selected, value]);
    }
  }, [selected, setSelected]);

  const handleSelectAll = useCallback((e) => {
    e.stopPropagation();
    const newOptions = [...new Set([...selected, ...visibleOptions.map(option => option.value)])];
    setSelected(newOptions);
  }, [selected, visibleOptions, setSelected]);

  const handleUnselectAll = useCallback((e) => {
    e.stopPropagation();
    setSelected([]);
  }, [setSelected]);

  const handleSearchChange = useCallback((e, {value}) => {
    setSearch(value);
  }, [setSearch]);

  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  useEffect(() => {
    if (!search) {
      setVisibleOptions(options);
    } else {
      setVisibleOptions(options.filter(option => option.text.toLowerCase().includes(search.toLowerCase())));
    }
  }, [options, search]);

  useEffect(() => {
    changeFilter(filterKey, selected);
    applyFilter();
  }, [selected]);

  return (
    <Dropdown
      text={`Filter ${label} ${selected.length > 0 ? `[${selected.length}]` : ''}`}
      multiple
      icon="filter"
      floating
      labeled
      button
      basic
      className="icon"
      loading={loading}
      error={selected.length > 0}
      pointing
    >
      <DropdownMenu>
        <Input
          icon="search"
          iconPosition="left"
          className="search"
          onChange={handleSearchChange}
          onClick={stopPropagation}
        />
        <DropdownDivider/>
        <DropdownHeader
          icon="filter"
          content={
            <span>
            <span>Select {label}</span>
            <span style={styles.icons}>
              <Icon name="check square outline" onClick={handleSelectAll} style={styles.icon}/>
              <Icon name="square outline" onClick={handleUnselectAll} style={styles.icon}/>
            </span>
          </span>
          }
        />
        <DropdownMenu scrolling>
          {visibleOptions.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={handleSelect}
              icon={selected.includes(option.value) ? 'check square outline' : 'square outline'}
              {...option}
            />
          ))}
        </DropdownMenu>
      </DropdownMenu>
    </Dropdown>
  );
}

export default reduxConnect(FilterMultiSelect);
