import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Popup } from "semantic-ui-react";

import TimeLine from "./TimeLine";
import StatusItem from "./StatusItem";
import { snakeToNormalCase } from "./utils";

const spanStyle = {
  cursor: "pointer",
};

const Trigger = ({ onClick, children }) => (
  <span style={spanStyle} onClick={onClick}>
    {children}
  </span>
);

Trigger.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

function TimelineStatusHistory({
  trigger,
  data,
  loadHistory,
  loading,
  statusName,
  docType,
  docId,
}) {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const mappedData = useMemo(() => {
    if (!data) return [];
    return data.map(({ id, attributes }) => ({ id, ...attributes }));
  }, [data]);

  useEffect(() => {
    if (!open) return;

    loadHistory({
      statusType: statusName,
      documentType: docType,
      documentId: docId,
    });
  }, [open]);

  return (
    <Popup
      trigger={<Trigger onClick={handleOpen}>{trigger}</Trigger>}
      open={open}
      onClose={handleClose}
      position="bottom center"
      fluid
    >
      <Popup.Header>[{snakeToNormalCase(statusName)}] History</Popup.Header>
      <Popup.Content>
        {loading ? "Loading..." : null}
        {!loading && !mappedData.length ? "No historical data found" : null}
        <TimeLine>
          {mappedData.map((row) => (
            <StatusItem
              key={row.id}
              status={row.current_status}
              prevStatus={row.previous_status}
              date={row.date}
              author={row.author_name}
            />
          ))}
        </TimeLine>
      </Popup.Content>
    </Popup>
  );
}

TimelineStatusHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      current_status: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      previous_status: PropTypes.string,
      date: PropTypes.string,
      author_name: PropTypes.string,
      map: PropTypes.func,
    }),
  ).isRequired,
  docId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  docType: PropTypes.string.isRequired,
  loadHistory: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  statusName: PropTypes.string.isRequired,
  trigger: PropTypes.node.isRequired,
};

export default TimelineStatusHistory;
