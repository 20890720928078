import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-semantic-toasts';
import { withRouter } from 'components/WithRouter';

import { CurrentUser } from '../../utils/user';

import { Link } from 'react-router-dom';
import { Button, Confirm, Icon, TableCell, Loader } from 'semantic-ui-react';

import './style.css';

const currentUser = new CurrentUser();

class TableRowGraphActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmOpened: false,
      loading: false
    };
  }

  toggleConfirm = () => this.setState({ isConfirmOpened: !this.state.isConfirmOpened, loading: false });

  destroy = () => {
    const { id, mutate } = this.props;
    this.setState({ loading: true });
    mutate({ variables: { id } })
      .then(() => this.toggleConfirm())
      .catch(e => {
        toast({ type: 'error', title: 'Something went wrong', description: e.message })
        this.setState({loading: false});
      })
  }

  render() {
    const { resource, id, confirm, children, mutate, copy, edit = '' } = this.props;
    const { isConfirmOpened } = this.state;

    const content = children || confirm || undefined;

    const icon = currentUser.isViewer() ? 'search plus' : 'edit';
    const label = currentUser.isViewer() ? 'Show' : 'Edit';

    return (
      <TableCell collapsing className="action-buttons">
        <Button.Group basic size="mini" >
          <Button icon animated="vertical" size="mini" as={Link}
            to={`/${resource}/${id}${edit}`}
          >
            <Button.Content visible>
              <Icon name={icon} />
            </Button.Content>
            <Button.Content hidden>{label}</Button.Content>
          </Button>
          {copy && !currentUser.isViewer() ?
            <Button icon animated="vertical" size="mini" as={Link}
              to={`/${resource}/copy/${id}`}
            >
              <Button.Content visible>
                <Icon.Group>
                  <Icon name="copy" />
                  <Icon corner name="add" />
                </Icon.Group>
              </Button.Content>
              <Button.Content hidden>Copy</Button.Content>
            </Button>
            : null
          }
          {(currentUser.isAdmin() || currentUser.canDelete() || currentUser.canDeletePO(resource)) ?
            <Button icon animated="vertical" size="mini" onClick={this.toggleConfirm}>
              <Button.Content visible>
                <Icon name="delete" />
              </Button.Content>
              <Button.Content hidden>Delete</Button.Content>
            </Button>
            : null
          }
          <Confirm
            content={content}
            open={isConfirmOpened}
            onConfirm={this.destroy}
            confirmButton={<Button loading={this.state.loading} primary>OK</Button>}
            onCancel={this.toggleConfirm}
            className="confirm"
          />
        </Button.Group>
      </TableCell>
    );
  }
}

TableRowGraphActions.propTypes = {
  children: PropTypes.element,
  confirm: PropTypes.string,
  copy: PropTypes.bool,
  edit: PropTypes.string,
  id: PropTypes.string,
  mutate: PropTypes.func,
  resource: PropTypes.string
}

export default withRouter(TableRowGraphActions);
