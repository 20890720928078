import isString from 'lodash/isString';
import serializeDataToQueryString from '../serializeDataToQueryString';
import axios from 'axios';
import { authHeaders, getApiBase } from '../api';

export const authRequestHeaders = (contentType = 'application/vnd.api+json') => (config) => {
  config.headers = authHeaders(contentType);
  return config;
};

const api = axios.create({ baseURL: getApiBase() });
api.interceptors.request.use(authRequestHeaders('application/vnd.api+json'));

const apiFormData = axios.create({ baseURL: getApiBase() });
apiFormData.interceptors.request.use(authRequestHeaders(undefined));

function getUrl(entity, { resource, url, query } = {}) {
  const resultEntity = resource ? resource : url ? url : entity;
  const parsedQuery = query ? `?${ serializeDataToQueryString(query) }` : '';

  return `/${ resultEntity }${ parsedQuery }`;
}

const getEntity = (data) => {
  if (isString(data)) {
    return data;
  }
  const { type, id, action } = data;

  return type + (!!id ? `/${id}` : '') + (!!action ? `/${action}` : '');
}


/**
 * @function read
 * @param {Object} data can be "{ id, type }"
 * @param {Object} override can has resource, url, query
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function read(data, override) {
  const entity = getEntity(data);
  const url = getUrl(entity, override);

  return api.get(url);
}

/**
 * @function create
 * @param {Object|String} data can be "{ id, type }" or "some_resource/:id"
 * @param {Object} override can has resource, url, query
 * @param {Object} payload
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function create(data, override, payload) {
  const entity = getEntity(data);
  const url = getUrl(entity, override);

  return api.post(url, payload);
}

/**
 * @function update
 * @param {Object|String} data can be "{ id, type, action }" or "some_resource/:id/some_action"
 *        example: {"invoices" || "invoices/2545" || "invoices/2545/pay"}
 * @param {Object} override can has resource, url, query
 * @param {Object} payload
 * @return {Promise} Promise then -> json, catch -> errors
 * @param {Boolean} formData
 */
export function update(data, override, payload, formData = false) {
  const entity = getEntity(data);
  const url = getUrl(entity, override);

  if (formData) {
    return apiFormData.patch(url, payload);
  } else {
    return api.patch(url, payload);
  }
}

/**
 * @function destroy
 * @param {Object|String} data can be "{ id, type }" or "some_resource/:id"
 * @param {Object} override can has resource, url, query
 * @param {Object} payload
 * @return {Promise} Promise then -> json, catch -> errors
 */
export function destroy(data, override, payload) {
  const entity = getEntity(data);
  const url = getUrl(entity, override);

  return api.delete(url, payload);
}
