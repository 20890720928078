import { dataPayload } from "./utils";
import * as api from "../api/adapters/json_api";

const type = "request_for_quotations";
const path = `api_resources/${type}`;


const requestForQuotations = {
  addRequisitionList: (rfqId, requisitionListId) => api.create(
    { type: path , id: rfqId, action: "add_requisition_list" },
    {},
    { requisition_list_id: requisitionListId }
  ),

  removeRequisitionList: (rfqId, requisitionListId) => api.create(
    { type: path , id: rfqId, action: "remove_requisition_list" },
    {},
    { requisition_list_id: requisitionListId }
  ),

  addSupplier: (rfqId, supplierId) => api.create(
    { type: path , id: rfqId, action: "add_supplier" },
    {},
    { supplier_id: supplierId }
  ),

  removeSupplier: (rfqId, supplierId) => api.create(
    { type: path , id: rfqId, action: "remove_supplier" },
    {},
    { supplier_id: supplierId }
  ),

  show: async (id) => api.read({type: path, id}, {}),

  create: async (attributes) => {
    const { rlIds = [], ...restAttributes } = attributes;
    const payload = dataPayload(type, restAttributes);

    if (rlIds.length) {
      const reqResp = await api.create({ type: path }, {}, payload);

      return requestForQuotations.addRequisitionList(reqResp.data.data.id, rlIds);
    }

    return api.create({ type: path , action: "create" }, {}, payload);
  },

  update: async (id, attributes) => {
    const payload = dataPayload(type, attributes, id);

    return api.update({ type: path, id }, {}, payload);
  }


}

export default requestForQuotations;
