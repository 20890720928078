import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { pathsByResource } from 'utils/paths';
import { goBack } from "utils/redirects";

export const withRouter = (Component) => {
  // The connected component
  const ConnectedComponent = (props) => {
    const { dispatch, location, ...rest } = props;
    const paths = useMemo(() => pathsByResource(location.pathname), [location.pathname]);
    const dispatchPush = (path) => dispatch(push(path));

    // Get the router methods and properties
    const history = useMemo(() => ({
      push: (path) => dispatchPush(path),
      goBack: () => goBack({ push: dispatchPush }, location.pathname)
    }), [dispatchPush, location.pathname])


    return <Component history={history} paths={paths} {...rest} />;
  };

  const mapStateToProps = (state) => ({
    location: state.router.location,
  });

  // Connect the component to the Redux store
  return connect(mapStateToProps)(ConnectedComponent);
};
