import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Label } from "semantic-ui-react";

const ClientStatusCell = ({ children, value }) => {
  const color = useMemo(() => {
    return {
      client: "green",
      interested: "blue",
      negotiations: "teal",
      past_client: "olive",
      failed: "red",
      postponed: "orange",
      neutral: "grey",
    }[value];
  }, [value]);

  return (
    <Label basic size="tiny" style={{ width: "100%" }} color={color}>
      {children}
    </Label>
  );
};

ClientStatusCell.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
};

export default ClientStatusCell;
