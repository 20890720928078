import PropTypes from "prop-types";
import React from "react";
import { Table } from "semantic-ui-react";
import TableHeaderCell from "./TableHeaderCell";
import DocsCounterCell from "./cells/DocsCounter";

const docCounterHeader = {
  invoices: "INV",
  purchase_orders: "PO",
  payment_instructions: "PI",
};
const docCounterTitle = <DocsCounterCell scaled docsCount={docCounterHeader} />;

const headerColumns = [
  { name: "icons", title: "I", sorted: false },
  { name: "short_name", title: "Name", sorted: true },
  { name: "activity_status", title: "Activity", sorted: false },
  { name: "client_status", title: "Status", sorted: false },
  { name: "location", title: "Location", sorted: false },
  { name: "vat_number", title: "VAT Number", sorted: true },
  { name: "tags", title: "Tags", sorted: false },
  { name: "company_types", title: "Types", sorted: false },
  { name: "last_updated", title: "Last Updated", sorted: false },
  {
    name: "invoices_count",
    title: docCounterTitle,
    sorted: false,
    textAlign: "center",
  },
  { name: "add_actions", title: "", sorted: false },
  { name: "reports", title: "Reports", sorted: false },
  { name: "actions", title: "Actions", sorted: false },
];
const TableHeader = ({ onColumnClick, sort, showSelectRL }) => {
  return (
    <Table.Header fullWidth>
      <Table.Row>
        {showSelectRL && <TableHeaderCell />}
        {headerColumns.map((column) => {
          const { sorted, name, ...restProps } = column;
          const onColumnClick = sorted ? onColumnClick : undefined;

          return (
            <TableHeaderCell
              column={column}
              onColumnClick={onColumnClick}
              sort={sort}
              key={name}
              {...restProps}
            />
          );
        })}
      </Table.Row>
    </Table.Header>
  );
};

TableHeader.propTypes = {
  onColumnClick: PropTypes.func,
  sort: PropTypes.any,
};

export default TableHeader;
