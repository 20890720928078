import {Link} from 'react-router-dom'
import withPopup from "components/utils/withPopup";
import SellwisorLogo from "assets/logos/Sellwisor";
import React, {useEffect, useCallback} from "react";
import {useLocalStorage} from "components/App/LocalStorageContext";
import {Icon, List, Label, Button, ListItem, ListHeader, ListContent} from "semantic-ui-react";

import './styles/RequestSegments.scss'
import {requestManager} from "../../requestManager";
import {RL_SELECTED_KEY, REQUEST_CREATED_KEY, SUPPLIERS_SELECTED_KEY} from "../utils";

export const RequisitionListSegment = () => {
  const [selectedItems, setSelectedItems] = useLocalStorage(RL_SELECTED_KEY, []);
  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);
  const [createdRequest, setCreatedRequest] = useLocalStorage(REQUEST_CREATED_KEY, null);

  const removeItem = useCallback((e, {itemKey}) => {
    const onSuccess = () => setSelectedItems(selectedItems.filter(({id}) => itemKey !== id));
    requestManager().removeRLFromRequest(createdRequest, itemKey, onSuccess);
  }, [createdRequest, selectedItems, setSelectedItems]);

  useEffect(() => {
    if (selectedItems && !selectedItems.length) {
      setCreatedRequest(null);
      setSelectedSuppliers([])
    }
  }, [selectedItems]);

  return (
    <>
      <Label as='a' attached="top">
        Selected Requisition Lists
      </Label>
      <List divided verticalAlign='middle'>
        {selectedItems.map(({id, attributes}) => (
          <ListItem key={`rl-${id}`}>
            <ListContent floated='right'>
              <Button size="mini" color="red" basic icon="remove" onClick={removeItem} itemKey={id}/>
            </ListContent>
            <ListContent>
              <ListHeader>{attributes?.full_title}</ListHeader>
              {`${attributes.vessel_name} / ${attributes.equipment?.name}`}
            </ListContent>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export const SupplierSegment = () => {
  const [selectedSuppliers, setSelectedSuppliers] = useLocalStorage(SUPPLIERS_SELECTED_KEY, []);
  const [createdRequest, setCreatedRequest] = useLocalStorage(REQUEST_CREATED_KEY, null);

  const removeSupplier = useCallback((e, {itemKey}) => {
    const onSuccess = () => setSelectedSuppliers(selectedSuppliers.filter(({id}) => itemKey !== id));
    requestManager().removeSupplierFromRequest(createdRequest, itemKey, onSuccess)
  }, [createdRequest]);

  useEffect(() => {
    const bc = new BroadcastChannel("purchasing_request_changes");
    bc.onmessage = (e) => {
      const {data: { action = null, payload = {} } = {}  } = e;
      if (action !== 'companyFormSubmitted') return;
      const supplierId = payload.id;
      const ids = selectedSuppliers.map(({id}) => id);
      if (ids.includes(supplierId)) {
        setTimeout(() => requestManager().loadSuppliers(ids, setSelectedSuppliers), 300)
      }
    }
    return () => bc.close();
  }, [selectedSuppliers]);

  return (
    <>
      <Label as='a' attached="top">
        Selected Suppliers
      </Label>
      <List divided verticalAlign='middle'>
        {selectedSuppliers.map(({id, attributes}) => (
          <ListItem key={`supplier-${id}`} className={!attributes.purchasing_email && 'warning'}>
            <ListContent floated='left'>
              {!attributes.purchasing_email && withPopup(
                <Icon color="red" name="warning sign"/>,
                () => <p>The supplier does not have a <b>PURCHASING EMAIL</b>. To create a quotation, you need to fill
                  in this field in the <b>COMPANY CARD</b>.</p>
              )}
              {attributes.connected_to_sellwisor &&
                withPopup(<SellwisorLogo width={14} height={14}/>, "Connected to Sellwisor")
              }
            </ListContent>
            <ListContent floated='right'>
              <Button size="mini" color="green" basic icon="edit" as={Link} to={`/companies/${id}/edit?tab=partnership`}
                      target={`/companies/${id}/edit`} rel="noopener noreferrer"/>
              <Button size="mini" color="red" basic icon="remove" onClick={removeSupplier} itemKey={id}/>
            </ListContent>
            <ListContent>
              <ListHeader>{attributes.short_name}</ListHeader>
              {`${attributes.location} / ${attributes.company_types}`}
            </ListContent>
          </ListItem>
        ))}
      </List>
    </>
  )
}
