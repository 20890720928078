
export const formatSum = (sum) => {
  let formattedSum = sum;
  if (typeof sum === "string") {
    formattedSum = parseFloat(sum);
  } else {
    formattedSum = sum;
  }
  return formattedSum.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  });
};
