import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon, Table } from "semantic-ui-react";

class TableHeaderCell extends Component {
  static propTypes = {
    column: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string
    }).isRequired,
    onColumnClick: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      direction: PropTypes.string,
      field: PropTypes.string
    }).isRequired
  }

  handleClick = () => {
    const { column: { name } = {}, onColumnClick } = this.props;
    onColumnClick(name);
  };

  handleDefaultSort = () => {
    const { onColumnClick } = this.props;
    onColumnClick("");
  };

  render() {
    const { column = {}, sort = {}, onColumnClick, ...restProps } = this.props;
    const sorted =
      column.name === sort.field
        ? sort.direction === "desc"
          ? "descending"
          : "ascending"
        : undefined;

    const isActionColumn = column.name === "actions";
    const sortActive = !!sort.field;

    const activeSortAction = sortActive ? this.handleDefaultSort : undefined;

    const sortAction = isActionColumn ? activeSortAction : this.handleClick;

    return (
      <Table.HeaderCell
        sorted={sorted}
        onClick={onColumnClick ? sortAction : undefined}
        textAlign={isActionColumn ? "right" : undefined}
        {...restProps}
      >
        {column.title}
        {sortActive && isActionColumn && (
          <span>
            <Icon name="sort amount down" /> Reset sorting
          </span>
        )}
      </Table.HeaderCell>
    );
  }
}

export default TableHeaderCell;
