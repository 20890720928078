import {useSelectOptions} from "../../../../utils/hooks";
import React from "react";
import FilterMultiSelect from "./FilterMultiSelect";

const tagSelectOptionParams = {type: 'acts_as_taggable_on/tags', f_label: 'name', f_value: 'name'};
const FilterTags = () => {
  const [tagLoading, tagOptions] = useSelectOptions(tagSelectOptionParams);


  return (
    <FilterMultiSelect
      loading={tagLoading}
      options={tagOptions}
      label="Tags"
      filterKey="tags"
    />
  );
}

export default FilterTags;
