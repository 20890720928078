import React, {useCallback, useState} from "react";
import { Button, Card, Image, Statistic } from "semantic-ui-react";

import { formatSum } from "./utils";


const StatementCard = ({companyRow, customer, companyStatementDownload, our = false}) => {

  const [loading, setLoading] = useState(false);

  const downloadPdf = useCallback(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 1200);

    companyStatementDownload({
      companyId: companyRow.company.id,
      companyName: companyRow.company.short_name,
      customerId: customer.id,
      customerName: customer.short_name,
      our
    })
  }, [companyRow, customer, companyStatementDownload, setLoading, our]);

  return(
    <Card className={our ? 'our-debt' : 'company-debt'}>
      <Card.Content>
        {
          companyRow.company.logo && <Image
            floated="right"
            size="mini"
            src={`data:image/png;base64,${companyRow.company.logo}`}
          />
        }
        <Card.Header>{companyRow.company.short_name}</Card.Header>
        <Card.Description textAlign="center">
          {companyRow.data.map((currencyRow) => {
            return (
              <Statistic size="mini" key={currencyRow.currency}>
                <Statistic.Value>
                  {formatSum(currencyRow.sum)}
                </Statistic.Value>
                <Statistic.Label>{currencyRow.currency}</Statistic.Label>
              </Statistic>
            );
          })}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            basic
            loading={loading}
            color="orange"
            icon="file pdf outline"
            content="Download statement"
            onClick={downloadPdf}
          />
        </div>
      </Card.Content>
    </Card>
  )
}

export default StatementCard;