import React, { useCallback } from 'react';
import debounce from 'lodash/debounce';
import {Button, Icon, Input} from 'semantic-ui-react';

import connect from '../reduxConnect';

import './styles/search.scss';

const DEBOUNCE_TIME = 700;

const Search = ({ onSearch, onClearSearch, search, isLoading }) => {

  return (
    <Input
      action={
        <Button.Group size="tiny">
          <Button icon onClick={ onClearSearch } className="search-action"><Icon name="delete" /></Button>
        </Button.Group>
      }
      icon="search"
      iconPosition="left"
      loading={ isLoading }
      onChange={ debounce(onSearch, DEBOUNCE_TIME, { leading: true }) }
      placeholder="Type for search..."
      value={ search }
      className="companies-search"
    />
  )
};

export default connect(Search);
