import PropTypes from "prop-types"
import {Icon, Table} from "semantic-ui-react";
import React, {useMemo, useCallback} from "react";
import SellwisorLogo from "assets/logos/Sellwisor";
import { TagChips } from "features/components/tags";
import TableRowGraphActions from "components/TableRowGraphActions";
import CompanyReports from "components/pages/Companies/CompanyReports";

import UpdatedCell from "./cells/Updated";
import DocsCounterCell from "./cells/DocsCounter";
import ClientStatusCell from "./cells/ClientStatus";
import AdditionalInfo from "./cells/AdditionalInfo";
import ActivityStatusCell from "./cells/ActivityStatus";
import withStatusHistory from "../../components/timeline-status-history";
import {requestManager} from "../../requisition-lists/requestManager";

const styles = {
  icon: {
    cursor: "pointer"
  }
}

const TableRow = ({ item, removeItem, loadData, showSelectRL, selectedSuppliers, setSelectedSuppliers, createdRequest }) => {
  const { id, attributes } = item;

  const destroy = useCallback(() => new Promise((resolve, reject) => {
      resolve(removeItem(id));
    }), [id, removeItem]);

  const isSelected = useMemo(() => !!selectedSuppliers.find(el => el.id === id), [selectedSuppliers, id]);

  const handleToggleSelect = useCallback(() => {
    if (isSelected) {
      const onSuccess = () => setSelectedSuppliers(selectedSuppliers.filter(el => el.id !== id));
      requestManager().removeSupplierFromRequest(createdRequest, id, onSuccess);
    } else {
      const onSuccess = () => setSelectedSuppliers([...selectedSuppliers, item]);
      requestManager().addSupplierToRequest(createdRequest, id, onSuccess);
    }
  }, [isSelected, createdRequest, id, setSelectedSuppliers, selectedSuppliers, item]);

  return (
    <Table.Row key={id} error={attributes.deleted}>
      {showSelectRL &&
        <Table.Cell>
          <Icon
            size="large"
            name={isSelected ? "check square outline" : "square outline"}
            onClick={handleToggleSelect}
            style={styles.icon}
          />
        </Table.Cell>
      }
      <Table.Cell textAlign="center" collapsing>
        {attributes.deleted ? <Icon name="trash alternate"/> : null}
        {attributes.connected_to_sellwisor ? <SellwisorLogo width={14} height={14} /> : null}
      </Table.Cell>
      <Table.Cell>
        {attributes.short_name}
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        {withStatusHistory(
          <ActivityStatusCell value={attributes.activity_status}>
            {attributes.activity_status_human}
          </ActivityStatusCell>,
          "activity_status",
          "Company",
          id,
        )}
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        {withStatusHistory(
          <ClientStatusCell value={attributes.client_status}>
            {attributes.client_status_human}
          </ClientStatusCell>,
          "client_status",
          "Company",
          id,
        )}
      </Table.Cell>
      <Table.Cell>{attributes.location}</Table.Cell>
      <Table.Cell>{attributes.vat_number}</Table.Cell>
      <Table.Cell collapsing>
        <TagChips
          tags={attributes.tags}
          itemType="Company"
          itemId={id}
          cb={loadData}
        />
      </Table.Cell>
      <Table.Cell collapsing>{attributes.company_types}</Table.Cell>
      <Table.Cell collapsing>
        <UpdatedCell lastUpdated={attributes.last_updated} />
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        <DocsCounterCell docsCount={attributes.docs_count} companyId={id} />
      </Table.Cell>
      <Table.Cell collapsing textAlign="center">
        <AdditionalInfo
          companyId={id}
          attributes={attributes}
          loadData={loadData}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <CompanyReports customerId={id} customerName={attributes.short_name} />
      </Table.Cell>
      <Table.Cell collapsing>
        <TableRowGraphActions resource="companies" id={id} mutate={destroy} />
      </Table.Cell>
    </Table.Row>
  );
};

TableRow.propTypes = {
  item: PropTypes.shape({
    attributes: PropTypes.shape({
      activity_status: PropTypes.string,
      activity_status_human: PropTypes.string,
      client_status: PropTypes.string,
      client_status_human: PropTypes.string,
      company_types: PropTypes.string,
      docs_count: PropTypes.object,
      last_updated: PropTypes.object,
      location: PropTypes.string,
      short_name: PropTypes.string,
      tags: PropTypes.array,
      vat_number: PropTypes.string
    }),
    id: PropTypes.number
  }).isRequired,
  loadData: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired
}

export default TableRow;
