import React, { useMemo } from "react";
import { Button, Card, Image, Modal, Statistic, Dimmer, Segment, Loader } from "semantic-ui-react";
import isEmpty from "lodash/isEmpty";

import StatementCard from "./StatementCard";

import './styles.scss';

const ModalStatement = (
  {
    hideStatementModal,
    showStatementModal,
    companyStatementDownload,
    showModal,
    data,
    loading
  }) => {

  const noDataCompany = useMemo(
    () => isEmpty(data?.report_table?.company ?? []),
    [data?.report_table?.company]
  );
  const noDataOur = useMemo(
    () => isEmpty(data?.report_table?.our ?? []),
    [data?.report_table?.our]
  );

  return (
    <Modal
      className="statement-modal"
      onClose={hideStatementModal}
      onOpen={showStatementModal}
      open={showModal}
    >
      <Modal.Header>Statements with {data?.customer?.short_name}</Modal.Header>
      <Modal.Content>
        <h4 className="green-text">Company's debts:</h4>
        {!noDataCompany &&
          <Card.Group>
            {
              data?.report_table?.company?.map(companyRow =>
                <StatementCard
                  key={companyRow.company.id}
                  companyRow={companyRow}
                  customer={data?.customer}
                  companyStatementDownload={companyStatementDownload}
                />)
            }
          </Card.Group>
        }
        { (noDataCompany && !loading) &&  <h3 className="center-align">This company has no debts to our companies</h3>}
        <h4 className="red-text">Our debts:</h4>
        {!noDataOur &&
        <Card.Group>
          {
            data?.report_table?.our?.map(companyRow =>
              <StatementCard
                our
                key={companyRow.company.id}
                companyRow={companyRow}
                customer={data?.customer}
                companyStatementDownload={companyStatementDownload}
              />)
          }
        </Card.Group>
        }
        { (noDataOur && !loading) &&  <h3 className="center-align">Our companies have no debts to this company</h3>}

        { loading &&
          <Segment>
            <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
          </Segment>
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Close"
          labelPosition="right"
          icon="checkmark"
          onClick={hideStatementModal}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalStatement;
