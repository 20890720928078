import PropTypes from "prop-types"
import { Button, Popup, Table } from "semantic-ui-react";
import React from "react";

const style = {
  contentStyle: {
    width: "400px"
  }
}

const PopupAdditionalInfo = ({ attributes, trigger }) => {
  return (
    <Popup trigger={trigger}>
      <Popup.Header>Company additional info</Popup.Header>
      <Popup.Content style={style.contentStyle}>
        <Table compact definition size="mini">
          <Table.Body>
            <Table.Row>
              <Table.Cell width="5">Created</Table.Cell>
              <Table.Cell width="11">
                {attributes.creation.created_at}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="5">Created by</Table.Cell>
              <Table.Cell width="11">
                {attributes.creation.created_by}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="5">Credit Term</Table.Cell>
              <Table.Cell width="11">{attributes.credit_term_human.default}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width="5">Last Credit Term</Table.Cell>
              <Table.Cell width="11">{attributes.credit_term_human.last}</Table.Cell>
            </Table.Row>

          </Table.Body>
        </Table>
      </Popup.Content>
    </Popup>
  );
};

PopupAdditionalInfo.propTypes = {
  attributes: PropTypes.shape({
    creation: PropTypes.shape({
      created_at: PropTypes.any,
      created_by: PropTypes.any
    }),
    credit_term_human: PropTypes.any
  }),
  trigger: PropTypes.any
}

export default PopupAdditionalInfo;
