import {invoices, purchaseOrders, paymentInstructions} from 'utils/resources'
import PopupBaseComponent from './PopupBaseComponent'

const invoiceFields = { invoices: 'presentation,item_url' }
const purchaseOrderFields = { purchase_orders: 'presentation,item_url' }
const paymentInstructionFields = { payment_instructions: 'presentation,item_url' }

const Invoices = ({trigger, companyId}) => <PopupBaseComponent api={invoices} filter={{by_company: companyId}} fields={invoiceFields} trigger={trigger} />

const PurchaseOrders = ({trigger, companyId}) => <PopupBaseComponent api={purchaseOrders} filter={{by_company: companyId}} fields={purchaseOrderFields} trigger={trigger} />

const PaymentInstructions = ({trigger, companyId}) => <PopupBaseComponent api={paymentInstructions} filter={{by_company: companyId}} fields={invoiceFields} trigger={trigger} />

export {
  Invoices,
  PurchaseOrders,
  PaymentInstructions
}
