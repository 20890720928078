import moment from 'moment';
import isDate from 'lodash/isDate';

export const tryParseDate = (dateString) => {
  if (isDate(dateString)) return dateString;
  if (!dateString) return null;

  if (typeof (dateString) === 'object') {
    return new Date(dateString.value);
  }

  if (dateString.includes('T')) {
    return new Date(dateString);
  }

  if (dateString.length === 10 && dateString.includes('-')) {
    return moment(dateString, 'YYYY-MM-DD').toDate();
  }

  try {
    return moment(dateString, 'dd.MM.yyyy').toDate();
  } catch (e) {
    return null;
  }
};
